import {
  VStack,
  Heading,
  Text,
  Input,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Box,
  List,
  ListItem,
  Icon,
  Spinner,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { FiCheck, FiTrash2 } from "react-icons/fi";
import { CustomDomainsTabProps } from "./CustomDomainsTab.types";

function CustomDomainsTab({
  domains,
  customDomain,
  isAdding,
  isDeletingDomain,
  isDeleteDialogOpen,
  domainToDelete,
  cancelRef,
  hostedProjectUrl,
  getDnsInstructions,
  onCustomDomainChange,
  onAddDomain,
  onDeleteClick,
  onCancelDelete,
  onConfirmDelete,
}: CustomDomainsTabProps) {
  return (
    <VStack spacing={6} align="stretch">
      <Box>
        <Heading size="md" mb={2}>
          Custom Domains
        </Heading>
        <Text mb={4}>Add a custom domain to your hosted project.</Text>

        <FormControl>
          <FormLabel>Domain</FormLabel>
          <HStack>
            <Input
              placeholder="example.com"
              value={customDomain}
              onChange={(e) => onCustomDomainChange(e.target.value)}
            />
            <Button
              colorScheme="blue"
              onClick={onAddDomain}
              isLoading={isAdding}
              isDisabled={!customDomain}
              minW="120px"
            >
              Add Domain
            </Button>
          </HStack>
        </FormControl>
      </Box>

      {domains.length > 0 && (
        <List spacing={4}>
          {domains.map((domain) => (
            <ListItem key={domain.url} p={4} borderWidth={1} borderRadius="md">
              <VStack align="start" spacing={3} width="100%">
                <HStack justify="space-between" width="100%">
                  <Text fontWeight="medium">{domain.url}</Text>
                  <HStack spacing={4}>
                    {domain.configured ? (
                      <HStack color="green.500">
                        <Icon as={FiCheck} />
                        <Text>Configured</Text>
                      </HStack>
                    ) : (
                      <HStack color="orange.500">
                        <Spinner size="sm" />
                        <Text>Pending Configuration</Text>
                      </HStack>
                    )}
                    <IconButton
                      icon={
                        isDeletingDomain === domain.url ? (
                          <Spinner size="sm" />
                        ) : (
                          <FiTrash2 />
                        )
                      }
                      aria-label="Delete domain"
                      variant="ghost"
                      colorScheme="red"
                      size="sm"
                      isLoading={isDeletingDomain === domain.url}
                      onClick={() => onDeleteClick(domain.url)}
                    />
                  </HStack>
                </HStack>

                <VStack align="start" spacing={1} width="100%">
                  <Text fontSize="sm">
                    {getDnsInstructions(domain.url).instructions}
                  </Text>
                  <Box p={3} bg="gray.50" borderRadius="md" width="100%">
                    <Text fontSize="sm">
                      Type: {getDnsInstructions(domain.url).type}
                      <br />
                      Name: {getDnsInstructions(domain.url).host}
                      <br />
                      Value: {getDnsInstructions(domain.url).value}
                    </Text>
                  </Box>
                  <Text fontSize="sm" color="gray.600">
                    {getDnsInstructions(domain.url).note}
                  </Text>
                </VStack>
              </VStack>
            </ListItem>
          ))}
        </List>
      )}

      <Box mt={4} p={4} bg="gray.50" borderRadius="md">
        <Text fontSize="sm" fontWeight="medium" mb={2}>
          Default Domain
        </Text>
        <Text fontSize="sm" color="gray.600">
          {hostedProjectUrl || "your-project-url.example.com"}
        </Text>
      </Box>

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancelDelete}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove Domain from Project
            </AlertDialogHeader>

            <AlertDialogBody>
              Would you like to remove the domain {domainToDelete} from your
              project?
              <Text mt={4} fontSize="sm" color="gray.600">
                After continuing, your project will no longer be accessible
                through this domain.
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCancelDelete}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onConfirmDelete}
                ml={3}
                isLoading={isDeletingDomain === domainToDelete}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
}

export default CustomDomainsTab;
